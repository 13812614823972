<template>
  <div class="home-container"
    :class="[station && station.shortcode ? `station-${station.shortcode}` : '', snowEnabled && !isDay ? 'night' : '']">
    <div v-if="temperature !== null" class="temperature">
      <span>{{ temperature }} °C</span> <span v-if="windspeed !== null">&middot; {{ windspeed }} km/h</span> <span
        v-if="weatherType">&middot; {{ weatherType }}</span>
    </div>
    <history-bubbles />
    <Snowf v-if="snowEnabled" :amount="50" :size="snowSize" :speed="snowSwing" :wind="windspeed" :opacity="0.8"
      :swing="1" :zIndex="0" :resize="true" :color="snowColor" />
  </div>
</template>

<script>
import HistoryBubbles from "@/components/Layout/HistoryBubbles";
import { mapGetters } from "vuex";

import Snowf from 'vue-snowf';

export default {
  name: "home-page",
  data: () => ({
    weather: {
      "temperature": null,
      "windspeed": null,
      "weathercode": null
    }
  }),
  components: {
    HistoryBubbles,
    Snowf
  },
  methods: {
    // fetch weather data from api
    async fetchWeather() {
      await fetch('https://api.open-meteo.com/v1/forecast?latitude=50.50&longitude=4.47&current_weather=true')
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.weather.temperature = data.current_weather.temperature;
          this.weather.windspeed = data.current_weather.windspeed;
          this.weather.weathercode = data.current_weather.weathercode;
        })/*
        .catch(error => {
          console.error('Error fetching weather data:', error);
        })*/;
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.fetchWeather();
    })
  },

  computed: {
    ...mapGetters(["station"]),
    snowEnabled() {
      return new Date().getMonth() === 11 || new Date().getMonth() === 0;
    },
    // grab the weather data, and assign amount, size, speed, wind, opacity, swing, image, zIndex, resize, color
    windspeed() {
      return this.weather?.windspeed ?? 0;
    },
    temperature() {
      return this.weather?.temperature ?? null;
    },
    snowSwing() {
      if (this.windspeed < 5) {
        return 0.5;
      } else if (this.windspeed < 10) {
        return 1;
      } else if (this.windspeed < 15) {
        return 1.5;
      } else {
        return 2;
      }
    },
    snowSize() {
      if (this.temperature < 0) {
        return 10;
      } else if (this.temperature < 10) {
        return 5;
      } else if (this.temperature < 20) {
        return 3;
      } else {
        return 1;
      }
    },
    snowColor() {
      if (this.temperature < 0) {
        return "#fff";
      } else if (this.temperature < 10) {
        return "#ccc";
      } else if (this.temperature < 20) {
        return "#ddd";
      } else {
        return "#eee";
      }
    },
    isDay() {
      const hours = new Date().getHours();
      return hours > 6 && hours < 20;
    },
    weatherCode() {
      return this.weather?.weathercode ?? 0;
    },
    weatherType() {
      // weather codes WMO to Dutch
      switch (this.weatherCode) {
        case 0:
          return "Heldere lucht";
        case 1:
          return "Licht bewolkt";
        case 2:
          return "Half bewolkt";
        case 3:
          return "Zwaar bewolkt";
        case 45:
        case 48:
          return "Mist";
        case 51:
        case 53:
        case 55:
          return "Motregen";
        case 56:
        case 57:
          return "IJzel";
        case 61:
        case 63:
        case 65:
          return "Regen";
        case 66:
        case 67:
          return "IJzel";
        case 71:
        case 73:
        case 75:
          return "Sneeuw";
        case 77:
          return "Korrelhagel";
        case 80:
        case 81:
        case 82:
          return "Regenbui";
        case 85:
        case 86:
          return "Sneeuwbui";
        case 95:
          return "Onweer";
        case 96:
        case 99:
          return "Onweer met hagel";
        default:
          return null
      }
    },
  },
};
</script>
