<template>
  <nav class="flex flex-wrap items-center justify-between">
    <router-link to="/" class="flex items-center justify-center flex-shrink-0 p-3 mr-6 text-white sm:w-auto"
      aria-label="index page">
      <!-- <IconElement class="block w-auto h-8 fill-current sm:hidden drop-shadow text-brownish" /> -->
      <LogoElement class="w-auto h-8 fill-current drop-shadow text-brownish" />
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 40 40" class="w-4 h-4 ml-2 back stroke-white">
        <g transform="matrix(1.6666666666666667,0,0,1.6666666666666667,0,0)">
          <path
            d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12C23.993,5.376,18.624,0.007,12,0z M15.223,8.255 c0,0.154-0.071,0.3-0.193,0.395l-4.053,3.15c-0.109,0.084-0.129,0.24-0.045,0.349c0.013,0.017,0.028,0.032,0.045,0.045l4.053,3.156 c0.122,0.095,0.193,0.241,0.193,0.395v2.687c0,0.276-0.223,0.5-0.499,0.501c-0.117,0-0.231-0.041-0.321-0.116l-7.719-6.433 c-0.212-0.177-0.241-0.492-0.064-0.704c0.019-0.023,0.041-0.044,0.064-0.064L14.4,5.183c0.212-0.177,0.527-0.148,0.704,0.064 c0.075,0.09,0.116,0.204,0.116,0.321L15.223,8.255z"
            stroke="none" fill="currentcolor" stroke-width="0" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
      </svg>
    </router-link>
    <div class="block p-3 ml-auto sm:hidden">
      <button
        class="flex items-center px-3 py-2 border border-transparent rounded outline-none text-brownish focus:border-black focus:text-black hover:text-black"
        v-on:click.prevent="toggleNavbar()" title="Menu">
        <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" v-if="!navBarActive" />
          <path v-else
            d="M10 8.586l-3.293-3.293-1.414 1.414 3.293 3.293-3.293 3.293 1.414 1.414 3.293-3.293 3.293 3.293 1.414-1.414-3.293-3.293 3.293-3.293-1.414-1.414-3.293 3.293-3.293-3.293-1.414 1.414 3.293 3.293z" />
        </svg>
      </button>
    </div>
    <div class="block transition duration-300 sm:flex sm:items-right sm:w-auto nav-container"
      :class="{ hidden: !navBarActive }">
      <!--<router-link to="/" class="inline-block px-4 py-2 mr-2 text-sm leading-none text-gray-500 border border-transparent rounded hover:text-white">Home</router-link>-->
      <!-- <router-link :to="{ name: 'programmering' }" class="transition duration-300" exact>Programmering</router-link> -->
      <!-- <a href="https://starradio.be" class="transition duration-300" rel="noopener" target="_blank">Festival</a> -->
      <!-- <router-link :to="{ name: 'extra' }" class="transition duration-300" exact>Extra</router-link> -->
      <a href="https://musicstars.online" target="_blank" rel="external" class="transition duration-300">Music Stars</a>
    </div>
  </nav>
</template>

<script>
import LogoElement from "@/components/Elements/LogoElement";
// import IconElement from "@/components/Elements/IconElement";
export default {
  name: "nav-bar",
  components: {
    // IconElement,
    LogoElement,
  },
  data: function () {
    return {
      navBarActive: false,
      popup: null,
    };
  },
  methods: {
    toggleNavbar() {
      this.navBarActive = !this.navBarActive;
    },
    openPopup() {
      // if(this.popup) this.popup.focus()
      this.popup = window.open(
        "https://listen.starradio.online",
        "starradio.online",
        "width=450,height=450,resizable=0,scrollbars=0,titlebar=0,status=0,toolbar=0"
      );
    },
  },
};
</script>
